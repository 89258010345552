import React, {Component} from 'react';
import {connect} from 'react-redux';
import "./styles.css";

class NotFound extends Component {
    render() {
        return (
            <>NotFound Page</>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({})

const mapStateToProps = (state) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(NotFound)
