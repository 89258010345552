import React, {Component} from 'react';
import {connect} from 'react-redux';
import './style.css';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import Home from './../../pages/Home';
import Design from "../../pages/Design";
import NotFound from "../../pages/NotFound";
import Success from "../../pages/Success";

class DefaultLayout extends Component {
    render() {

        return (
            <Router>
                <div>
                    <Switch>
                        <Route exact path="/">
                            <Home />
                        </Route>
                        <Route path="/design">
                            <Design />
                        </Route>
                        <Route path="/success">
                            <Success />
                        </Route>
                        <Route path="*">
                            <NotFound />
                        </Route>
                    </Switch>
                </div>
            </Router>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({})

const mapStateToProps = (state) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(DefaultLayout)
