import React, {Component} from 'react';
import {connect} from 'react-redux';
import "./styles.css";
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/js/src/util.js';
import 'antd/dist/antd.css';
import {Modal, Collapse} from 'antd';

const {Panel} = Collapse;
export const isMobileDevice = () => {
    try {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    } catch (e) {
        return false;
    }
}

class Menu extends Component {
    state = {
        loading: false,
        visible: false,
        isContact: false,
    };

    showModal = (isContact) => {
        this.setState({
            visible: true,
            isContact: isContact
        });
    };

    handleOk = () => {
        this.setState({loading: true});
        setTimeout(() => {
            this.setState({loading: false, visible: false});
        }, 3000);
    };

    handleCancel = () => {
        this.setState({visible: false});
    };

    render() {
        const {visible} = this.state;
        return (
            <>
                <div className="dropdown">
                    <div className="dropdown-custom"
                         id="dropdownMenuButton"
                         data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {isMobileDevice() ? '' : 'Menu'}
                        <i aria-label="icon: menu"
                           className="anticon anticon-menu _1lXcaok-aq_O72HeosJSnA qPFD7iDQm7UMv6PGc-i0H">
                            <svg viewBox="64 64 896 896" focusable="false" className="" data-icon="menu" width="1em"
                                 height="1em" fill="currentColor" aria-hidden="true">
                                <path
                                    d="M904 160H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8zm0 624H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8zm0-312H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8z"></path>
                            </svg>
                        </i>
                    </div>
                    <div className="dropdown-menu  dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                        <span className="dropdown-item"
                              onClick={() => {
                                  this.showModal(false);
                              }}>Câu Hỏi Thường Gặp</span>
                        <span className="dropdown-item"
                              onClick={() => {
                                  this.showModal(true);
                              }}>Liên hệ</span>
                    </div>
                </div>
                <Modal
                    visible={visible}
                    title={this.state.isContact ? 'Liên hệ' : 'Câu hỏi thường gặp'}
                    footer={null}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    {!this.state.isContact ? <Collapse accordion>
                            {window.__DATA__.qna.map((obj, i) =>
                                <Panel header={obj.title} key={i}>
                                    <p>{obj.answer}</p>
                                </Panel>)}
                        </Collapse> :
                        <article className="ant-typography">
                            <div className="ant-typography">{window.__DATA__.contact.slogan}</div>
                            <div className="ant-divider ant-divider-horizontal" role="separator"></div>
                            <div className="ant-typography"><span
                                className="ant-typography"><strong>Hotline</strong></span>: <a
                                href="tel:0783226668">{window.__DATA__.contact.hotline}</a></div>
                            <div className="ant-typography"><span
                                className="ant-typography"><strong>Email</strong></span>:
                                &nbsp;
                                <a target="_blank"
                                   rel="noopener noreferrer"
                                   href={'mailto:' + window.__DATA__.contact.email}>
                                    {window.__DATA__.contact.email}
                                </a>
                            </div>
                            <div className="ant-typography"><span
                                className="ant-typography"><strong>Facebook</strong></span>:
                                &nbsp;
                                <a target="_blank"
                                   rel="noopener noreferrer"
                                   href={window.__DATA__.contact.fb}>
                                    {window.__DATA__.contact.fb}
                                </a>
                            </div>
                            <div className="ant-typography"><span className="ant-typography"><strong>Văn phòng</strong></span>:
                            </div>
                            <ul>
                                {window.__DATA__.contact.branches.map((branch, i) =>
                                    <li key={i}>
                                        <div className="ant-typography"><span
                                            className="ant-typography"><strong>{branch.location}</strong></span>
                                            : {branch.address}
                                        </div>
                                    </li>)}
                            </ul>
                        </article>
                    }
                </Modal>
            </>

        )
    }
}

const mapDispatchToProps = (dispatch) => ({})

const mapStateToProps = (state) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Menu)
