import React, {Component} from 'react';
import {connect} from 'react-redux';
import "./styles.css";
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/js/src/util.js';
import 'antd/dist/antd.css';
import Menu from "../../components/Menu";
import {Link} from "react-router-dom";
import { Result, Button } from 'antd';

class Success extends Component {
    render() {
        return (
            <>
                <nav className="custom-nav navbar navbar-light justify-content-between">
                    <img src={window.__DATA__.homeLogo}
                         width="156"
                         height="50"
                         alt="Khung ảnh thông minh, không cần dùng đinh"/>
                    <Menu/>
                </nav>
                <Result
                    status="success"
                    title="Đặt hàng thành công"
                    subTitle="Đơn hàng đã được đặt thành công. Chúng tôi sẽ liên hệ với bạn
                        qua số điện thoại trong thời gian sớm nhất."
                    extra={[
                        <Link to={'design'} key="design"><Button type="primary">
                            Mua thêm
                        </Button></Link>,
                        <Link to={'/'} key="home"><Button>Về trang chủ</Button></Link>,
                    ]}
                />
            </>

        )
    }
}

const mapDispatchToProps = (dispatch) => ({})

const mapStateToProps = (state) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Success)
