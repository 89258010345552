import {applyMiddleware, createStore} from 'redux';
import reducers from "./reducers";
import thunk from 'redux-thunk';
import logger from 'redux-logger';

let middleWares = [thunk];
if(process.env.NODE_ENV === "development") {
    middleWares.push(logger);
}

const store = createStore(reducers, applyMiddleware(...middleWares));

export default store;