import React, { Component } from 'react';
import { Provider } from 'react-redux';
import store from './store.js';
import DefaultLayout from "./layouts/DefaultLayout";
import { ThemeProvider } from '@rmwc/theme';

class App extends Component {

  render() {
    return (
      <Provider store={store}>
        <ThemeProvider options={{
          primary: "#ffffff",
          secondary: "yellow",
        }}>
          <DefaultLayout/>
        </ThemeProvider>
      </Provider >

    );
  }
}

export default App;
