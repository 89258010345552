import React, {Component} from 'react';
import {connect} from 'react-redux';
import "./styles.css";
import 'antd/dist/antd.css';
import Menu, {isMobileDevice} from "../../components/Menu";
import ImgCrop from 'antd-img-crop';
import {Link} from "react-router-dom";
import {
    Drawer, Divider, Upload, Alert, Form, Input,
    Button, message, Statistic, Modal
} from 'antd';
import {detectMob} from "../Home";
import axios from 'axios';
import {withRouter} from 'react-router-dom';

export const validateRule = {
    required: 'Cung cấp đầy đủ thông tin'
}
function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}
class Design extends Component {
    state = {
        fileList: [],
        visible: false,
        activeIndex: 0,
        previewVisible: false,
        previewImage: '',
        previewTitle: '',
        form: {
            "files": [],
            "fullName": "",
            "phone": "",
            "address": "",
            "email": "",
            "notes": ""
        }
    }
    handleCancel = () => this.setState({ previewVisible: false });
    componentDidMount() {
        const list = localStorage.getItem('fileList');
        if (list) {
            try {
                let files = JSON.parse(list);
                this.setState({
                    fileList: files
                })
            } catch (e) {

            }
        }
    }

    variantId() {
        let result = null;
        window.__DATA__.product_data.variants.forEach((variant, i) => {
            if (this.state.activeIndex === i) {
                result = variant.id;
            }
        });
        return result;
    }

    getPriceById(id) {
        let result = null;
        window.__DATA__.product_data.variants.forEach((variant, i) => {
            if (this.state.activeIndex === i) {
                result = variant.price;
            }
        });
        return result;
    }

    onFinish = () => {
        let files = JSON.parse(localStorage.getItem('fileList'));
        if (files.length) {
            let fileData = [];
            files.forEach((item) => {
                if (item && item.response && item.response.error_code === 0) {
                    fileData.push({
                        "origin": localStorage.getItem(item.uid + '_uid'),
                        "transform": item.response.data
                    })
                }
            });
            axios.post('https://pix.lala.com.vn/order', {
                ...this.state.form,
                productId: window.__DATA__.product_data.id,
                variantId: this.variantId(),
                files: fileData
            }).then((res) => {
                if (res && res.data && res.data.error_code === 0) {
                    files.forEach((item) => {
                        if (item && item.response && item.response.error_code === 0) {
                            localStorage.removeItem(item.uid + '_uid');
                        }
                    });
                    localStorage.setItem('fileList', JSON.stringify(''));
                    this.props.history.push('/success')
                } else {
                    message.error(res.data.error_message ||
                        ('Không tạo được đơn hàng (' + res.data.error_code + ')'));
                }
            }, (error) => {
                message.error(error);
            })
        }
    };

    onChange = ({fileList: newFileList}) => {
        newFileList = newFileList.filter((file) => {
            if (file.status === 'error') {
                message.error('Lỗi kết nối! Không thể gửi hình đi !');
            }
            return file.status !== 'error';
        });
        newFileList = newFileList.map((file) => {
            if(file.response && file.response.error_code === 0) {
                file.thumbUrl = file.response.data;
            }
            return file;
        });
        localStorage.setItem('fileList', JSON.stringify(newFileList));
        this.setState({
            fileList: newFileList.filter(file => !!file.status)
        })
    };

    onPreview = async file => {
        try {
            let src = file.thumbUrl;
            if (!src) {
                src = await getBase64(file.originFileObj);
            }

            this.setState({
                previewImage: src,
                previewVisible: true,
                previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
            });
        } catch (e) {
            console.log(e);
        }
    };
    showDrawer = () => {
        this.setState({
            visible: true
        })
    };

    onClose = () => {
        this.setState({
            visible: false
        })
    };

    onChangeBox(e, i) {
        this.setState({
            activeIndex: i
        })
    }

    uploadFile(file) {
        const formData = new FormData();
        formData.append("image", file);
        axios.post('https://pix.lala.com.vn/upload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((res) => {
            console.log(res.data);
            if (res && res.data && res.data.error_code === 0) {
                localStorage.setItem(file.uid + '_uid', res.data.data);
            }
        });
    }

    beforeCrop = (file, fileList) => {
        if (!(file.size / 1024 / 1024 <= 50)) {
            message.error('Kích thước tối đa là 50MB!');
            return false;
        }
        switch (file.type) {
            case 'image/x-png':
                this.uploadFile(file);
                return true
            case 'image/jpeg':
                this.uploadFile(file);
                return true
            case 'image/png':
                this.uploadFile(file);
                return true
            default:
                message.error('Chỉ hỗ trợ định dạng file PNG và JPG.');
                return false
        }
    }

    hasShippingFee() {
        return this.state.fileList.length < window.__DATA__.paymentInfo.quantity;
    }


    render() {
        return (
            <>
                <nav className="custom-nav navbar navbar-light justify-content-between bg-white mb-0">
                    <Link to={'/'}>
                        <div className="custom-btn ant-btn ant-btn-link ant-btn-lg">
                            <i aria-label="icon: left"
                               className="anticon anticon-left">
                                <svg viewBox="64 64 896 896" focusable="false"
                                     data-icon="left" width="1em"
                                     height="1em" fill="currentColor" aria-hidden="true">
                                    <path
                                        d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 0 0 0 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z"></path>
                                </svg>
                            </i>
                            {isMobileDevice() ? '' : 'Quay lại'}
                        </div>
                    </Link>
                    <Link to={'/'}>
                        <img src="https://tinipix.com/desktop-assets/static/images/logo-white-bg.png"
                             width="156"
                             height="50"
                             alt="Khung ảnh thông minh, không cần dùng đinh"/>
                    </Link>
                    <Menu/>
                </nav>
                <div className="section-wrapper">
                    <ul className="custom-list">
                        {window.__DATA__.product_data.variants.map((variant, i) =>
                            <li key={i} onClick={
                                (e) => this.onChangeBox(e, i)
                            }>
                                <div className={(this.state.activeIndex === i ? 'active' : '') +
                                " list-item ant-btn ant-btn-link ant-btn-block"}>
                                    <img src={variant.icon}
                                         alt="Khung ảnh thông minh, không cần dùng đinh"/>
                                    {variant.color}
                                </div>
                            </li>)}
                    </ul>
                    <Modal
                        visible={this.state.previewVisible}
                        title={this.state.previewTitle}
                        footer={null}
                        onCancel={this.handleCancel}
                    >
                        <img alt="example" style={{ width: '100%' }} src={this.state.previewImage} />
                    </Modal>
                    <ImgCrop beforeCrop={this.beforeCrop}
                             quality={1}
                             rotate>
                        <Upload
                            className={'frame' + this.state.activeIndex + " uploader-section"}
                            action="https://pix.lala.com.vn/upload"
                            listType="picture-card"
                            method="post"
                            name="images"
                            accept="image/x-png,image/png,image/jpeg"
                            withCredentials={true}
                            fileList={this.state.fileList}
                            onChange={this.onChange}
                            onPreview={this.onPreview}
                            openFileDialogOnClick={true}
                        >
                                <span className="position-relative">
                                    <span className="ant-upload-drag-icon">
                                        <i aria-label="icon: upload" className="anticon anticon-upload">
                                            <svg
                                                viewBox="64 64 896 896" focusable="false" className=""
                                                data-icon="upload"
                                                width="1em" height="1em" fill="currentColor" aria-hidden="true"><path
                                                d="M400 317.7h73.9V656c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V317.7H624c6.7 0 10.4-7.7 6.3-12.9L518.3 163a8 8 0 0 0-12.6 0l-112 141.7c-4.1 5.3-.4 13 6.3 13zM878 626h-60c-4.4 0-8 3.6-8 8v154H214V634c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v198c0 17.7 14.3 32 32 32h684c17.7 0 32-14.3 32-32V634c0-4.4-3.6-8-8-8z"></path></svg></i></span><span
                                    className="ant-upload-text">Tải ảnh lên</span>
                                    <span className="ant-upload-hint">Hỗ trợ định dạng png, jpg, jpeg. Kích thước tối đa 50MB.</span>
                                </span>
                        </Upload>
                    </ImgCrop>
                </div>
                <div className="upload-picture" onClick={() => this.showDrawer()}>
                    <button type="button" className="upload-btn">
                        <i aria-label="icon: shopping-cart"
                           className="anticon anticon-shopping-cart">
                            <svg viewBox="0 0 1024 1024" focusable="false" className="" data-icon="shopping-cart"
                                 width="1em" height="1em" fill="currentColor" aria-hidden="true">
                                <path
                                    d="M922.9 701.9H327.4l29.9-60.9 496.8-.9c16.8 0 31.2-12 34.2-28.6l68.8-385.1c1.8-10.1-.9-20.5-7.5-28.4a34.99 34.99 0 0 0-26.6-12.5l-632-2.1-5.4-25.4c-3.4-16.2-18-28-34.6-28H96.5a35.3 35.3 0 1 0 0 70.6h125.9L246 312.8l58.1 281.3-74.8 122.1a34.96 34.96 0 0 0-3 36.8c6 11.9 18.1 19.4 31.5 19.4h62.8a102.43 102.43 0 0 0-20.6 61.7c0 56.6 46 102.6 102.6 102.6s102.6-46 102.6-102.6c0-22.3-7.4-44-20.6-61.7h161.1a102.43 102.43 0 0 0-20.6 61.7c0 56.6 46 102.6 102.6 102.6s102.6-46 102.6-102.6c0-22.3-7.4-44-20.6-61.7H923c19.4 0 35.3-15.8 35.3-35.3a35.42 35.42 0 0 0-35.4-35.2zM305.7 253l575.8 1.9-56.4 315.8-452.3.8L305.7 253zm96.9 612.7c-17.4 0-31.6-14.2-31.6-31.6 0-17.4 14.2-31.6 31.6-31.6s31.6 14.2 31.6 31.6a31.6 31.6 0 0 1-31.6 31.6zm325.1 0c-17.4 0-31.6-14.2-31.6-31.6 0-17.4 14.2-31.6 31.6-31.6s31.6 14.2 31.6 31.6a31.6 31.6 0 0 1-31.6 31.6z"></path>
                            </svg>
                        </i>
                        <span>Đặt hàng</span></button>
                </div>
                <Drawer
                    title="Đặt hàng"
                    placement="right"
                    closable={true}
                    onClose={this.onClose}
                    visible={this.state.visible}
                    className={(detectMob() ? 'mobile-view' : 'normal-view') + ' custom-form'}
                >
                    <Divider plain={true}>Thông tin giao hàng</Divider>
                    <Form name="nest-messages"
                          layout="vertical"
                          onFinish={() => this.onFinish()} validateMessages={validateRule}>
                        <Form.Item
                            name={['user', 'name']}
                            label="Họ và tên"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                            required
                        >
                            <Input placeholder="Họ và tên"
                                   onChange={(e) => {
                                       const form = this.state.form;
                                       this.setState({
                                           form: {
                                               ...form,
                                               fullName: e.target.value
                                           }
                                       })
                                   }}
                                   value={this.state.form.fullName}/>
                        </Form.Item>
                        <Form.Item
                            required
                            name={['user', 'phone']}
                            label="Số điện thoại"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input placeholder="Số điện thoại di động"
                                   onChange={(e) => {
                                       const form = this.state.form;
                                       this.setState({
                                           form: {
                                               ...form,
                                               phone: e.target.value
                                           }
                                       })
                                   }}
                                   value={this.state.form.phone}/>
                        </Form.Item>
                        <Form.Item required name={['user', 'address']}
                                   rules={[
                                       {
                                           required: true,
                                       },
                                   ]}
                                   label="Địa chỉ">
                            <Input placeholder="Địa chỉ nhận hàng"
                                   onChange={(e) => {
                                       const form = this.state.form;
                                       this.setState({
                                           form: {
                                               ...form,
                                               address: e.target.value
                                           }
                                       })
                                   }}
                                   value={this.state.form.address}/>
                        </Form.Item>
                        <Form.Item
                            name={['user', 'email']}
                            label="Email"
                            rules={[
                                {
                                    type: 'email',
                                },
                            ]}
                        >
                            <Input placeholder="nguyenvana@gmail.com"
                                   onChange={(e) => {
                                       const form = this.state.form;
                                       this.setState({
                                           form: {
                                               ...form,
                                               email: e.target.value
                                           }
                                       })
                                   }}
                                   value={this.state.form.email}/>
                        </Form.Item>
                        <Form.Item name={['user', 'note']} label="Lưu ý">
                            <Input placeholder="Lưu ý khi sản xuất và giao hàng"
                                   onChange={(e) => {
                                       const form = this.state.form;
                                       this.setState({
                                           form: {
                                               ...form,
                                               notes: e.target.value
                                           }
                                       })
                                   }}
                                   value={this.state.form.notes}/>
                        </Form.Item>
                        <Divider plain>Thanh toán</Divider>
                        <Alert message={window.__DATA__.paymentInfo.title} type="success"/>
                        <div className="mt-2 ant-descriptions ant-descriptions-small ant-descriptions-bordered">
                            <div className="ant-descriptions-view">
                                <table>
                                    <tbody>
                                    <tr className="ant-descriptions-row">
                                        <th className="ant-descriptions-item-label ant-descriptions-item-colon">
                                            Số lượng&nbsp;
                                            {this.state.fileList.length}
                                            &nbsp;cái
                                        </th>
                                        <td className="ant-descriptions-item-content" colSpan="1">
                                            <Statistic decimalSeparator
                                                       valueStyle={{fontSize: '16px'}}
                                                       suffix="đ"
                                                       value={this.state.fileList.length * this.getPriceById(this.state.activeIndex)}/>
                                        </td>
                                    </tr>
                                    <tr className="ant-descriptions-row">
                                        <th className="ant-descriptions-item-label ant-descriptions-item-colon">Vận
                                            chuyển
                                        </th>
                                        <td className="ant-descriptions-item-content" colSpan="1">
                                            {this.hasShippingFee() ?
                                                <Statistic decimalSeparator
                                                           valueStyle={{fontSize: '16px'}}
                                                           suffix="đ"
                                                           value={window.__DATA__.paymentInfo.shippingFee}/> :
                                                'Miễn phí'
                                            }
                                        </td>
                                    </tr>
                                    <tr className="ant-descriptions-row">
                                        <th className="ant-descriptions-item-label ant-descriptions-item-colon"><strong>Tổng
                                            cộng</strong></th>
                                        <td className="ant-descriptions-item-content" colSpan="1">
                                            <strong>
                                                <Statistic decimalSeparator
                                                           valueStyle={{fontSize: '16px'}}
                                                           suffix="đ"
                                                           value={this.state.fileList.length * this.getPriceById(this.state.activeIndex) +
                                                           (this.hasShippingFee() ? window.__DATA__.paymentInfo.shippingFee : 0)}/>
                                            </strong>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <Form.Item>
                            <Button className="order-btn mt-2" type="primary" htmlType="submit">
                                Xác nhận đơn hàng
                            </Button>
                        </Form.Item>
                    </Form>
                </Drawer>
            </>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({})

const mapStateToProps = (state) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Design))
