import React from 'react';
import ReactDOM from 'react-dom';
import 'here-js-api/styles/mapsjs-ui.css';
import 'here-js-api/scripts/mapsjs-core';
import 'here-js-api/scripts/mapsjs-service';
import 'here-js-api/scripts/mapsjs-ui';
import 'here-js-api/scripts/mapsjs-mapevents';
import 'here-js-api/scripts/mapsjs-clustering';
import 'here-js-api/scripts/mapsjs-places';
import "jquery/dist/jquery.min";

import './index.css';
import App from './App.jsx';
import "./appStyles";
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
