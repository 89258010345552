import React, {Component} from 'react';
import {connect} from 'react-redux';
import "./styles.css";
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/js/src/util.js';
import 'antd/dist/antd.css';
import {
    Link,
} from "react-router-dom";
import Menu from "../../components/Menu";
import { Carousel } from 'antd';

export const detectMob = () => {
    const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
    ];

    return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
    });
}

class Home extends Component {
    state= {
        fileList: []
    }

    componentDidMount() {
        const list = localStorage.getItem('fileList');
        if (list) {
            try {
                let files = JSON.parse(list);
                this.setState({
                    fileList: files
                })
            } catch (e) {

            }
        }
    }
    render() {
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 5,
            slidesToScroll: 5,
            autoplay: true,
            autoplaySpeed: 4000,
            initialSlide: 0,
            pauseOnHover: true,
            variableWidth: true,
            arrow: false,
            draggable: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                    }
                }
            ]
        };

        return (
            <>
                <nav className="custom-nav navbar navbar-light justify-content-between">
                    <img src={window.__DATA__.designLogo}
                         width="156"
                         height="50"
                         alt="Khung ảnh thông minh, không cần dùng đinh"/>
                    <Menu/>
                </nav>
                <div className="home-content">
                    <div className="home-title">
                        Khung ảnh thông minh, không cần dùng đinh
                    </div>
                    <div className={(detectMob()? 'custom-frame-height': '') +' mx-auto position-relative'}>
                            <iframe className={detectMob()? 'mob-frame' : 'iframe-content'}
                                    title="My content"
                                    src={window.__DATA__.videoLink}
                                    frameBorder="0"/>
                    </div>
                </div>
                <div className="custom-carousel">
                    <Carousel {...settings}>
                        {window.__DATA__.carouselList.map((obj, i)=>
                            <div key={i}>
                                <img src={obj.link}
                                     alt={obj.alt}/>
                            </div>)}
                    </Carousel>
                </div>
                <Link to={'design'}>
                    <div className="upload-picture">
                        <button type="button" className="upload-btn"><i
                            aria-label="icon: upload" className="anticon anticon-upload">
                            <svg viewBox="64 64 896 896" focusable="false" className="" data-icon="upload" width="1em"
                                 height="1em" fill="currentColor" aria-hidden="true">
                                <path
                                    d="M400 317.7h73.9V656c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V317.7H624c6.7 0 10.4-7.7 6.3-12.9L518.3 163a8 8 0 0 0-12.6 0l-112 141.7c-4.1 5.3-.4 13 6.3 13zM878 626h-60c-4.4 0-8 3.6-8 8v154H214V634c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v198c0 17.7 14.3 32 32 32h684c17.7 0 32-14.3 32-32V634c0-4.4-3.6-8-8-8z"></path>
                            </svg>
                        </i>
                            <span>Tải ảnh lên {this.state.fileList && this.state.fileList.length? '(' + this.state.fileList.length + ')': ''}</span>
                        </button>
                    </div>
                </Link>
            </>

        )
    }
}

const mapDispatchToProps = (dispatch) => ({})

const mapStateToProps = (state) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Home)
