import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from "axios";

export const loadRevenue = createAsyncThunk(
    'revenue/loadRevenue',
    async (data, thunkAPI) => {
        const response = await axios.get(`abc`);
        return response.data
    }
);
export const loadFee = createAsyncThunk(
    'fee/loadFee',
    async (data, thunkAPI) => {
        const response = await axios.get(`abqq`);
        return response.data
    }
);

export const RevenueSlice = createSlice({
    name: 'menu',
    initialState: {
        revenue: [],
        revenueLoading: false,
        revenueError: false,
        fee: [],
        feeLoading: false,
        feeError: false,
    },
    reducers: {
        updateRevenue(state, action) {
            state.revenue[action.payload - 1]['reconcile_status'] = 1;
        },
        updateFee(state, action) {
            state.fee[action.payload - 1]['reconcile_status'] = 1;
        },
    },
    extraReducers: {
        [loadRevenue.fulfilled]: (state, action) => {
            if (action.payload && action.payload.data) {
                state.revenue = action.payload.data.map((item, i) => {
                    item['stt'] = i + 1;
                    return item;
                });
                state.revenueLoading = false;
            } else {
                state.revenueError = true;
                state.revenueLoading = false;
            }
        },
        [loadRevenue.rejected]: (state) => {
            state.revenueError = true;
            state.revenueLoading = false;
        },
        [loadRevenue.pending]: (state) => {
            state.revenueLoading = true;
        },

        [loadFee.fulfilled]: (state, action) => {
            if (action.payload && action.payload.data) {
                state.fee = action.payload.data.map((item, i) => {
                    item['stt'] = i + 1;
                    return item;
                });
                state.feeLoading = false;
            } else {
                state.feeError = true;
                state.feeLoading = false;
            }
        },
        [loadFee.rejected]: (state) => {
            state.feeError = true;
            state.feeLoading = false;
        },
        [loadFee.pending]: (state) => {
            state.feeLoading = true;
        },
    }
});

export const {updateRevenue, updateFee} = RevenueSlice.actions;